<template>
  <div class="box">
    <div class="zhu_zhaung_tu_box">
      <v-chart :options="options" autoresize />
    </div>

    <span class="unit">单位: {{ unit }}</span>
  </div>
</template>

<script>
export default {
  name: "kongXinBingTu",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    ispreview: Boolean,
  },
  data() {
    return {
      unit: '',
      options: {
        title: {
          text: '',
          top: '0',
          left: '0',
          textStyle: {
            color: '#fff'
          },
          show: true
        },
        tooltip: {
          trigger: "item",
          show: false,
        },
        // 提示
        tooltip: {
          trigger: "item",
          show: true,
          textStyle: {
            color: '#fff',
            fontSize: 16
          }
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
          textStyle: {
            color: '#fff',
            fontSize: 14
          }
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
              decal: {
                symbol: "none",
              },
            },
            label: {
              normal: {
                show: true,
                fontSize: 14,
                formatter:function(params) {
                  return params.data.name + ' ' + params.data.value + ' ' + params.data.unit;
                }
              }
            },
            // labelLine: {
            //   show: true,
            // },
            data: [],
            itemStyle: {
              decal: {
                symbol: "none",
              },
              normal: {
                color: function(params) {
                  var colorList = [
                    "#65d186",
                    "#2BCB95",
                    "#F6BD16",
                    "#c05bdd",
                    "#f29e3c",
                    "#7a65f2",
                    "#65d186",
                    "#FF3B30"
                  ]; //每根柱子的颜色
                  return colorList[params.dataIndex];
                },
                shadowColor: "rgba(0,160,221,1)",
                //模糊细数
                shadowBlur: 4,
              }
            },
          },
        ],
      },
    };
  },
  watch: {
    value(val) {
      this.options.series[0].data = val.data
      this.unit = val.unit
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.box {
  width: 100%;
  height: 90%;
  position: relative;
}

.zhu_zhaung_tu_box {
  width: 100%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // padding: 0 10px;
  box-sizing: border-box;
}

.unit { position: absolute; left: 10px; bottom: 5px; color: #fff; font-size: 14px; }
</style>
